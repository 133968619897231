import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Alert, Box, Button } from "@mui/material";
import {
  ArrowBack as ArrowBackIcon,
  // VolumeOff as VolumeOffIcon,
  // VolumeUp as VolumeUpIcon,
} from "@mui/icons-material";
import { useAuth } from "../../util/auth";
import {
  getEmbodimentById,
  getEmbodimentDefaultPlayObject,
} from "../../api/embodimentManagement";
import { getFeagiSessionInfo } from "../../api/feagiSessionManagement";
import DisplayMarkdown from "../../util/DisplayMarkdown";
import handleLeavePlay from "../../util/handleLeavePlay";
import CustomDialog from "../CustomDialog";
import PageLoader from "../PageLoader";
import PlayPhysical from "./PlayPhysical";
import Microbit from "../embodiments-code/Microbit";
import PlayConnected from "./PlayConnected";
import CustomSnackbar from "../../util/CustomSnackbar";

const Play = () => {
  const auth = useAuth();
  const accessToken = auth.user.accessToken;
  const [loading, setLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({
    message: "",
    severity: "", // "success", "info", "warning", or "error"
  });
  const [isSessionValid, setIsSessionValid] = useState(null);
  const [leavePlayPath, setLeavePlayPath] = useState("");
  // Data from URL
  const { id: embodimentId } = useParams();
  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const sessionId =
    queryParameters.get("session_id") || queryParameters.get("id");
  const clusterId =
    queryParameters.get("cluster_id") || queryParameters.get("cluster");
  // Embodiments
  const [config, setConfig] = useState({});
  const [embodiment, setEmbodiment] = useState({});
  const [embodimentType, setEmbodimentType] = useState(""); // "user-ws-device", "nrs-html-app", "nrs-bt-device"
  const [physicalConnected, setPhysicalConnected] = useState(null);
  const [hasConnectedOnce, setHasConnectedOnce] = useState(false);
  const [isBluetoothOpen, setIsBluetoothOpen] = useState(false);
  const [instructions, setInstructions] = useState("");
  const [instructionsOpen, setInstructionsOpen] = useState(
    (embodimentType === "user-ws-device" ||
      embodimentType === "nrs-bt-device") &&
      !hasConnectedOnce
      ? false
      : true
  );

  // const [isMuted, setIsMuted] = useState(true);

  // Show connect page only on initial load, not on disconnect
  useEffect(() => {
    if (physicalConnected === true) {
      setHasConnectedOnce(true);
    }
  }, [physicalConnected]);

  // Fetch embodiment for current session
  useEffect(() => {
    async function callFetchEmbodiment() {
      try {
        // Check session validity
        if (!sessionId) {
          setSnackbar({
            message:
              "URL is missing required info. Please return to Plays to retry.",
            severity: "error",
          });

          return;
        }
        const sessionRes = await getFeagiSessionInfo(accessToken, sessionId);
        if (sessionRes.data.state === "running") {
          setIsSessionValid(true);
        } else {
          throw new Error("Session is not running: ", sessionRes.data.state);
        }
        // Get embodiment info
        const embodimentRes = await getEmbodimentById(
          accessToken,
          embodimentId
        );
        const data = embodimentRes.data;
        setEmbodiment(data);
        if (!data) {
          throw new Error("Embodiment data is missing.");
        }
        setEmbodimentType(data.agent_type);
        setInstructions(data.play_notes);
        const dataConfig = data.play_obj?.configuration;
        if (
          !dataConfig ||
          (data.agent_type === "nrs-html-app" && !dataConfig.player_1)
        ) {
          const defaultObjRes = await getEmbodimentDefaultPlayObject(
            accessToken
          );
          if (
            !defaultObjRes?.data?.configuration ||
            (defaultObjRes?.data?.agent_type === "nrs-html-app" &&
              !defaultObjRes.player_1)
          ) {
            throw new Error(
              "Either embodiment configuration is missing, or agent type is 'nrs-html-app' without player_1."
            );
          } else {
            setConfig(defaultObjRes.data.configuration);
            return;
          }
        }
        setConfig(dataConfig);
      } catch (err) {
        // Handle errors
        console.error(err);
        console.log("err message:", err.message);
        if (!isSessionValid) {
          setSnackbar({
            message:
              "This play session is invalid or expired. Please click Play Select to start fresh.",
            severity: "error",
          });
        } else {
          setSnackbar({
            message:
              "An error occurred fetching the required info. Play elements may be missing or non-functional. Please reload, or restart from Plays if issues persist.",
            severity: "error",
          });
        }
      } finally {
        setLoading(false);
      }
    }

    accessToken && callFetchEmbodiment();
  }, [accessToken, embodimentId, isSessionValid, sessionId]);

  // const toggleMute = () => {
  //   setIsMuted(!isMuted);
  // };

  if (loading) return <PageLoader />;

  if (!embodiment?.embodiment_title && snackbar?.severity === "error") {
    return (
      <Box
        sx={{
          width: "100%",
          height: "100%",
          pt: 3,
          px: { xs: 3, md: 6, lg: 24, xl: 48 },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 2,
        }}
      >
        <Alert severity="error">{snackbar.message}</Alert>
        <Button variant="outlined" startIcon={<ArrowBackIcon />} href="/play">
          Play Select
        </Button>
      </Box>
    );
  }

  return (
    <>
      {(embodimentType === "user-ws-device" ||
        embodimentType === "nrs-bt-device") &&
      !hasConnectedOnce ? (
        <PlayPhysical
          accessToken={accessToken}
          setError={setSnackbar}
          sessionId={sessionId}
          clusterId={clusterId}
          embodiment={embodiment}
          setPhysicalConnected={setPhysicalConnected}
          setIsBluetoothOpen={setIsBluetoothOpen}
        />
      ) : (
        <PlayConnected
          accessToken={accessToken}
          setSnackbar={setSnackbar}
          queryParameters={queryParameters}
          sessionId={sessionId}
          clusterId={clusterId}
          embodiment={embodiment}
          embodimentId={embodimentId}
          embodimentType={embodimentType}
          config={config}
          instructions={instructions}
          setInstructionsOpen={setInstructionsOpen}
          setLeavePlayPath={setLeavePlayPath}
          physicalConnected={physicalConnected}
          setPhysicalConnected={setPhysicalConnected}
          setIsBluetoothOpen={setIsBluetoothOpen}
        />
      )}

      {/* Bluetooth device */}
      {/* {isBluetoothOpen && ( */}
      <Microbit
        isMicrobitOpen={isBluetoothOpen}
        setIsMicrobitOpen={setIsBluetoothOpen}
        isMicrobitConnected={physicalConnected}
        setIsMicrobitConnected={setPhysicalConnected}
      />
      {/* )} */}

      {/* Play instructions dialog */}
      {instructionsOpen && instructions && (
        <CustomDialog
          header=""
          text=""
          isOpen={instructionsOpen}
          handleClose={() => setInstructionsOpen(false)}
          confirmAction={() => setInstructionsOpen(false)}
          richText={<DisplayMarkdown markdownFromDb={instructions} />}
        />
      )}

      {/* Message */}
      <CustomSnackbar
        message={snackbar.message}
        setMessage={setSnackbar}
        severity={snackbar.severity}
        autoHideTime={snackbar.severity === "error" ? null : 4000}
      />

      {/* Confirm leave page */}
      {leavePlayPath && (
        <CustomDialog
          header="Leave the current page and end your play session?"
          // text="(No data will be lost.)"
          isOpen={leavePlayPath}
          handleClose={() => setLeavePlayPath("")}
          confirmAction={() =>
            handleLeavePlay(
              auth.user?.accessToken,
              leavePlayPath,
              setLeavePlayPath
            )
          }
          cancelAction={() => setLeavePlayPath("")}
        />
      )}
    </>
  );
};

export default Play;
