import { Box, Typography } from "@mui/material";
import NavbarBehaviors from "../navbar/NavbarBehaviors";

const PlayPersonality = ({
  personality,
  setPersonality,
  embodiment,
  embodimentId,
  accessToken,
  setSnackbar,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 1,
        }}
      >
        {/* Header */}
        <Typography variant="h3" sx={{ fontSize: "1.3rem", fontWeight: 600 }}>
          Active Personality
        </Typography>
        {/* Change personality button */}
        <NavbarBehaviors
          accessToken={accessToken}
          setSnackbar={setSnackbar}
          embodimentId={embodimentId}
          handleChange={(personalityObj) => setPersonality(personalityObj)}
        />
      </Box>

      {/* Personality title */}
      <Typography sx={{ fontWeight: 600, color: "accents.light" }}>
        {personality?.title ||
          (embodiment?.embodiment_title &&
            `Playing with ${embodiment.embodiment_title}`) ||
          "[Current Personality]"}
      </Typography>
    </Box>
  );
};

export default PlayPersonality;
