import { forwardRef, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../../util/auth";
// import { useDarkMode } from "../../util/theme";
import {
  AppBar,
  Alert,
  Badge,
  Box,
  Button,
  Container,
  Dialog,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Snackbar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  AccountCircle as AccountCircleIcon,
  CenterFocusStrong as CenterFocusStrongIcon, // camera/screen cap
  Reviews as ReviewsIcon,
  School as SchoolIcon,
  Science,
  Person,
  Logout,
  Style,
  Info,
  Slideshow,
  PrecisionManufacturing,
  Hub,
  // Insights,
} from "@mui/icons-material";
import Section from "../Section";
import InactivityTimer from "../../util/InactivityTimer";
import getBrowserInfo from "../../util/getBrowserInfo";
// import withTimeout from "../../util/timeout";
// import NavbarExperimentDropdown from "./NavbarExperimentDropdown";
// import Grafana from "../embodiments-code/Grafana";
import NavbarFreezeBrain from "./NavbarFreezeBrain";
import ExperimentCardMemory from "../lab-experiments/ExperimentCardMemory";
import BookModal from "../BookModal";
import BrainVisualizerIntro from "../BrainVisualizerIntro";
import NavbarExperiment from "./NavbarExperiment";
import NavbarGenome from "./NavbarGenome";
import NavbarEmbodiment from "./NavbarEmbodiment";
import NavbarFitness from "./NavbarFitness";
import NavbarBehaviors from "./NavbarBehaviors";
import NavbarFeedbackSection from "./NavbarFeedbackSection";
import ScreenCapture from "../embodiments-code/ScreenCapture";
import { getDropdownInfo } from "../../api/misc";
import { getTutorialData } from "../../api/tutorials";
import { getDemosData } from "../../api/demos";
import logo from "../../img/logo-800-gray.png";
import CustomLink from "../../util/CustomLink";
import CustomDialog from "../CustomDialog";
import handleLeavePlay from "../../util/handleLeavePlay";
import CustomSnackbar from "../../util/CustomSnackbar";
import FullscreenLoader from "../../util/FullscreenLoader";

const Navbar = forwardRef((props, ref) => {
  const auth = useAuth();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const accessToken = auth.user?.accessToken;
  const [fullscreenLoader, setFullscreenLoader] = useState(false);
  // Params
  const path = location.pathname;
  const queryParameters = new URLSearchParams(location.search);
  const sessionId = queryParameters.get("id");
  const clusterId = queryParameters.get("cluster");
  // Access screen size anywhere
  const theme = useTheme();
  const isSmOrSmaller = useMediaQuery(theme.breakpoints.down("md"));
  const [navActive, setNavActive] = useState(false);
  // const darkMode = useDarkMode();
  // const logoColor = darkMode.value ? "white" : "black";
  const logoColor = "white";
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [menuState, setMenuState] = useState(null);
  const [tutorialMenuState, setTutorialMenuState] = useState(null);
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  // const [settingsState, setSettingsState] = useState(null);
  const [tutorialOpen, setTutorialOpen] = useState(false);
  const [demosOpen, setDemosOpen] = useState(false);
  const [leavePlayPath, setLeavePlayPath] = useState("");
  // brain visualizer page
  const [isMobile, setIsMobile] = useState(false);
  const [experiment, setExperiment] = useState(null);
  const [brainVisualizerIntroOpen, setBrainVisualizerIntroOpen] =
    useState(false);
  const [isEmbodimentConnected, setIsEmbodimentConnected] = useState(false);
  const [isMicrophoneChecked, setIsMicrophoneChecked] = useState(false);
  const [godotEmbodimentOpen, setGodotEmbodimentOpen] = useState(false);
  const [screenCaptureOpen, setScreenCaptureOpen] = useState(false);
  const [embodimentFitness, setEmbodimentFitness] = useState(null);
  const [fitnessOpen, setFitnessOpen] = useState(false);
  const [awaitAugment, setAwaitAugment] = useState(false);
  const [refetchExperiment, setRefetchExperiment] = useState(false);

  // Colors to indicate environment
  const backgroundColor =
    process.env.REACT_APP_NODE_ENV === "staging"
      ? "rgb(27 73 74)"
      : "transparent";

  // Use inverted logo if specified and we are in dark mode
  // const logo = props.logo;
  // props.logoInverted && darkMode.value ? props.logoInverted : props.logo;

  // useEffect(() => {
  //   try {
  //     auth && auth.user && auth.refreshRoles();
  //   } catch (err) {
  //     setError(
  //       "There was an error retrieving your account information. This may impact your experience. Please refresh or contact us if you continue to experience issues."
  //     );
  //   }
  //   // Ignore dependency array demands -- auth.js handles role setting after auth changes and putting auth here causes circular dependency
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    // Remove scrollbars and bound draggables only on brain-vis page
    document.body.style.overflow = "";
    // Get browser info
    const callGetBrowserInfo = async () => {
      const info = await getBrowserInfo();
      setIsMobile(info?.isMobile);
    };
    if (path.startsWith("/brain-visualizer")) {
      document.body.style.overflow = "hidden";
      callGetBrowserInfo();
    }
  }, [path]);

  // Fetch experiment dropdown info
  useEffect(() => {
    const getLatestExperimentInfo = async () => {
      try {
        const accessToken = auth?.user?.accessToken;
        const res = await getDropdownInfo(accessToken, sessionId);
        setExperiment(res.data);
      } catch (error) {
        console.error(error);
        setError(
          error.message ||
            "There was an error fetching your experiments. Please reload."
        );
      }
    };

    if (
      path.startsWith("/brain-visualizer") &&
      auth?.user?.accessToken &&
      sessionId
    ) {
      getLatestExperimentInfo();
    }
  }, [path, sessionId, auth.user, refetchExperiment]);

  useEffect(() => {
    if (!path.startsWith("/brain-visualizer")) {
      setGodotEmbodimentOpen(false);
      props.setWebcamOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  const handleSignOut = async (event) => {
    try {
      if (!auth) {
        throw new Error("Auth object not found:", auth);
      }
      setFullscreenLoader(true);
      await auth.signout();
      // Only change route if the sign out process has completed successfully
      // history.push("/auth/signin");
      // history.push(`${process.env.REACT_APP_COMPOSER_URL}/auth/signin`);
      window.location.href = `/auth/signin`;
    } catch (error) {
      console.error("Signout failed: ", error);
    } finally {
      setFullscreenLoader(false);
    }
  };

  // Account dropdown
  const handleOpenMenu = (event, id) => {
    // Store clicked element (to anchor the menu to) and the menu id so we can tell which menu is open
    setMenuState({ anchor: event.currentTarget, id });
  };

  const handleCloseMenu = () => {
    setMenuState(null);
  };

  // Tutorial dropdown
  const handleOpenTutorialMenu = (event, id) => {
    setTutorialMenuState({ anchor: event.currentTarget, id });
  };

  const handleCloseTutorialMenu = () => {
    setTutorialMenuState(null);
  };

  // Tutorial modal
  const handleTutorialClose = () => {
    setTutorialOpen(false);
  };

  // Demos modal
  const handleDemosClose = () => {
    setDemosOpen(false);
  };

  const linkClick = (targetPath) => {
    const clusterParam = params.get("cluster") || params.get("cluster_id");
    if (path.startsWith("/play") && clusterParam) {
      setLeavePlayPath(targetPath);
    } else {
      window.location.href = targetPath;
    }
  };

  // const handleOpenSettings = (event, id) => {
  //   setSettingsState({ anchor: event.currentTarget, id });
  // };

  // const handleCloseSettings = () => {
  //   setSettingsState(null);
  // };
  const isAuthPage =
    location.pathname === "/" ||
    location.pathname === "/business" ||
    location.pathname.startsWith("/auth");
  const shouldOnlyShowIfActive = isSmOrSmaller && isAuthPage;

  // Show navbar depending on scroll from top
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 64) {
        setNavActive(true);
        console.log("set true");
      } else {
        setNavActive(false);
      }
    };

    isSmOrSmaller &&
      isAuthPage &&
      window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isAuthPage, isSmOrSmaller]);

  const customSetError = (obj) => {
    setError(obj.message);
  };

  return (
    <>
      {!location?.pathname?.startsWith("/embodiment") && auth?.user && (
        <InactivityTimer />
      )}
      <Section
        bgColor={shouldOnlyShowIfActive && navActive ? "black" : props.color}
        size="auto"
      >
        <AppBar
          position="static"
          color="transparent"
          elevation={0}
          ref={ref}
          sx={{
            display: shouldOnlyShowIfActive && !navActive ? "none" : "flex",
            position:
              shouldOnlyShowIfActive && navActive ? "fixed" : "relative",
            zIndex: 100,
            backgroundColor:
              shouldOnlyShowIfActive && navActive
                ? "background.default"
                : props.color,
          }}
        >
          <Container
            disableGutters={true}
            maxWidth="100vw" // this is a set Container prop, don't move it to sx
            sx={{
              px: { xs: "10px", md: "20px" },
              backgroundColor: backgroundColor,
            }}
          >
            <Toolbar
              disableGutters={true}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Tooltip title={auth.user ? "Lab" : "Home"}>
                <span>
                  {/* This button passes on params if no user (signup analytics), 
                  else checks path and either shows confirmation modal or redirects */}
                  <Button
                    component={!auth.user ? CustomLink : "button"}
                    href={auth.user ? null : "/"}
                    type="mui"
                    onClick={auth.user ? () => linkClick("/lab") : undefined}
                    sx={{
                      minWidth: { xs: "24px", md: "64px" },
                      textDecoration: "none",
                      color: logoColor,
                      fontWeight: "bold",
                    }}
                  >
                    {isSmOrSmaller && path.startsWith("/brain-visualizer") ? (
                      <img
                        src={logo}
                        alt="Neuraville logo"
                        style={{ maxWidth: "30px" }}
                      />
                    ) : (
                      <>
                        Neurorobotics
                        <Badge
                          badgeContent="BETA"
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          sx={{
                            ".MuiBadge-badge": {
                              top: "30%",
                              right: "-25px",
                              transform: "translateY(-50%)", // Centers vertically
                              fontSize: "0.43rem",
                              fontWeight: "bold",
                              backgroundColor: "transparent",
                              color: "primary.main",
                            },
                          }}
                        >
                          <span
                            style={{ fontWeight: "400", marginLeft: "5px" }}
                          >
                            Studio
                          </span>
                        </Badge>
                      </>
                    )}
                  </Button>
                </span>
              </Tooltip>
              {/* <div sx={{flexGrow: 1}} />
            <div sx={{flexGrow: 1}} /> */}
              {path.startsWith("/brain-visualizer") && !isMobile && (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      gap: { xs: "5px", md: "20px" },
                      borderLeft: "1px solid white",
                      marginLeft: { xs: "10px", md: "20px" },
                      marginRight: { xs: "10px", md: 0 },
                      paddingLeft: { xs: "10px", md: "20px" },
                    }}
                  >
                    <NavbarExperiment
                      accessToken={accessToken}
                      experimentId={experiment?.experiment_id}
                      experimentTitle={experiment?.experiment_title}
                      clusterId={clusterId}
                      sessionId={sessionId}
                      setError={setError}
                      setMessage={setMessage}
                      awaitAugment={awaitAugment}
                      isSmOrSmaller={isSmOrSmaller}
                    />
                    <NavbarEmbodiment
                      {...props}
                      experiment={experiment}
                      accessToken={accessToken}
                      setError={setError}
                      setMessage={setMessage}
                      sessionId={sessionId}
                      clusterId={clusterId}
                      isEmbodimentConnected={isEmbodimentConnected}
                      setIsEmbodimentConnected={setIsEmbodimentConnected}
                      godotEmbodimentOpen={godotEmbodimentOpen}
                      setGodotEmbodimentOpen={setGodotEmbodimentOpen}
                      isMicrophoneChecked={isMicrophoneChecked}
                      setIsMicrophoneChecked={setIsMicrophoneChecked}
                      screenCaptureOpen={screenCaptureOpen}
                      setScreenCaptureOpen={setScreenCaptureOpen}
                      setEmbodimentFitness={setEmbodimentFitness}
                      awaitAugment={awaitAugment}
                      setRefetchExperiment={setRefetchExperiment}
                      isSmOrSmaller={isSmOrSmaller}
                    />
                    <NavbarGenome
                      experiment={experiment}
                      accessToken={accessToken}
                      error={error}
                      setError={setError}
                      setMessage={setMessage}
                      setRefetchExperiment={setRefetchExperiment}
                      awaitAugment={awaitAugment}
                      setAwaitAugment={setAwaitAugment}
                      isSmOrSmaller={isSmOrSmaller}
                    />
                    {experiment?.has_personalities && (
                      <NavbarBehaviors
                        embodimentId={experiment?.embodiment_id}
                        accessToken={accessToken}
                        setSnackbar={customSetError}
                        awaitAugment={awaitAugment}
                      />
                    )}
                  </Box>
                  <ScreenCapture
                    screenCaptureOpen={screenCaptureOpen}
                    setScreenCaptureOpen={setScreenCaptureOpen}
                    sessionId={sessionId}
                    clusterId={clusterId}
                    setError={setError}
                  />
                  {/* Augment await message */}
                  <Snackbar
                    open={awaitAugment}
                    onClose={() => setAwaitAugment(false)}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    sx={{
                      "&.MuiSnackbar-root": { top: "7px" },
                    }}
                  >
                    <Alert severity="info">
                      Augmentation in progress. Please follow the instructions
                      in the brain visualizer.
                    </Alert>
                  </Snackbar>
                </>
              )}
              <Box sx={{ flexGrow: 1 }} />
              <Box sx={{ flexGrow: 1 }} />
              <Box sx={{ flexGrow: 1 }} />
              <Box sx={{ flexGrow: 1 }} />
              {path.startsWith("/brain-visualizer") && !isMobile && (
                <>
                  {embodimentFitness && (
                    <Tooltip title="Brain fitness. Click for more details">
                      <span>
                        <Button
                          variant="outlined"
                          size="small"
                          disabled={embodimentFitness === null}
                          onClick={() => setFitnessOpen(true)}
                          sx={{
                            marginRight: { xs: "5px", lg: "40px" },
                            minWidth: { xs: "auto", md: "64px" },
                            fontWeight: "600",
                            borderColor: "#fff",
                            color: "#fff",
                            "&:hover": {
                              borderColor: "#ffffff8f",
                            },
                          }}
                        >
                          {!isSmOrSmaller && "Fitness "}
                          {embodimentFitness}%
                        </Button>
                      </span>
                    </Tooltip>
                  )}
                  <Box
                    sx={{
                      height: "30.75px", // match Button height
                      display: "flex",
                      alignItems: "center",
                      gap: "12px",
                      border: "1px solid rgb(87, 87, 87)",
                      borderRadius: "5px",
                      px: { xs: "5px", md: "10px" },
                      py: "5px",
                      marginRight: { xs: "5px", lg: "40px" },
                    }}
                  >
                    <Tooltip
                      title={
                        isEmbodimentConnected || godotEmbodimentOpen
                          ? "Embodiment Status: Connected"
                          : "Embodiment Status: Not Connected"
                      }
                    >
                      <Box
                        sx={{
                          display: "flex",
                          fontSize: "1.5rem",
                          color:
                            isEmbodimentConnected || godotEmbodimentOpen
                              ? "success.main"
                              : "accents.main",
                        }}
                      >
                        <PrecisionManufacturing />
                      </Box>
                    </Tooltip>
                    {/* <Tooltip
                    title={
                      isMicrophoneChecked
                        ? "Mic Status: Connected"
                        : "Mic Status: Not Connected"
                    }
                  >
                    <MicIcon
                      style={{
                        color: isMicrophoneChecked
                          ? "success"
                          : "rgb(87, 87, 87)",
                      }}
                    />
                  </Tooltip> */}
                    <Tooltip
                      title={
                        props.webcamOpen
                          ? "Webcam: Connected"
                          : screenCaptureOpen
                          ? "Screen Capture: Connected"
                          : "Webcam & Screen Capture: Not Connected"
                      }
                    >
                      <Box
                        sx={{
                          display: "flex",
                          fontSize: "1.5rem",
                          color:
                            props.webcamOpen || screenCaptureOpen
                              ? "success.main"
                              : "accents.main",
                        }}
                      >
                        <CenterFocusStrongIcon />
                      </Box>
                    </Tooltip>
                  </Box>

                  {/* Grafana */}
                  {/* <Grafana awaitAugment={awaitAugment} /> // this is same thing as below, don't double
                  <Tooltip title="Monitor brain activity [PRO]">
                    <Link
                      href={`https://${sessionId}-feagi.${clusterId}.neurorobotics.studio/docs`}
                    >
                      <IconButton
                        style={{ borderRadius: "5px", padding: "5px" }}
                        disabled={awaitAugment}
                        // disabled={true} // change to role !== pro etc.
                      >
                        <Insights
                          title="Monitor brain activity [PRO]"
                          sx={{
                            margin: "0 5px",
                          }}
                        />
                      </IconButton>
                    </Link>
                  </Tooltip> */}

                  {/* Freeze brain */}
                  {(auth.user?.roles?.includes("pro-user") ||
                    auth.user?.roles?.includes("ultimate-user")) && (
                    <>
                      <NavbarFreezeBrain
                        accessToken={accessToken}
                        experiment={experiment}
                        sessionId={sessionId}
                        setError={setError}
                        setMessage={setMessage}
                        awaitAugment={awaitAugment}
                      />
                      {/* Change memory tier */}
                      <ExperimentCardMemory />
                    </>
                  )}

                  {/* Settings */}
                  {/* <Tooltip title="Settings">
                    <IconButton
                      color="inherit"
                      style={{ borderRadius: "5px", padding: "5px" }}
                      onClick={(event) =>
                        handleOpenSettings(event, "settings-menu")
                      }
                    >
                      <SettingsIcon
                        title="settings"
                        sx={{
                          margin: "0 5px",
                        }}
                      />
                    </IconButton>
                  </Tooltip> */}

                  {/* <Menu
                    id="settings-menu"
                    open={
                      settingsState && settingsState.id === "settings-menu"
                        ? true
                        : false
                    }
                    anchorEl={settingsState && settingsState.anchor}
                    getContentAnchorEl={undefined}
                    onClick={handleCloseSettings}
                    onClose={handleCloseSettings}
                    keepMounted={true}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  > */}
                  {/* EXAMPLE: <MenuItem component={Link} to="/lab" > */}
                  {/* <MenuItem onClick={handleVersionsOpen}>Versions</MenuItem> */}
                  {/* <MenuItem
                      onClick={() =>
                        console.log("Need to add API to get change history")
                      }
                    >
                      Change History
                    </MenuItem> */}
                  {/* </Menu> */}

                  {/* Fitness Dialog */}
                  <NavbarFitness
                    fitnessOpen={fitnessOpen}
                    setFitnessOpen={setFitnessOpen}
                    sessionId={sessionId}
                    clusterId={clusterId}
                  />
                </>
              )}
              {/* <Hidden smUp={true} implementation="css">
              <IconButton
                onClick={() => {
                  setDrawerOpen(true);
                }}
                color="inherit"
                style={{ borderRadius: "5px", padding: "5px" }}
              >
                <MenuIcon />
              </IconButton>
            </Hidden> */}
              {/* <Hidden xsDown={true} implementation="css"> */}
              <Hidden implementation="css">
                {!auth.user && (
                  <Button
                    component={CustomLink}
                    href="/auth/signin"
                    type="mui"
                    color="inherit"
                  >
                    Sign in
                  </Button>
                )}

                {auth.user && (
                  <>
                    <Box display="flex" gap="5px">
                      {/* Feedback */}
                      <Tooltip title="Give feedback / Report issues">
                        <IconButton
                          color="inherit"
                          style={{ borderRadius: "5px", padding: "5px" }}
                          onClick={() => setFeedbackOpen(true)}
                        >
                          <ReviewsIcon
                            title="contact"
                            sx={{ margin: "0 5px" }}
                          />
                        </IconButton>
                      </Tooltip>
                      <Dialog
                        open={feedbackOpen}
                        onClose={() => {
                          setFeedbackOpen(false);
                        }}
                      >
                        <NavbarFeedbackSection
                          setFeedbackOpen={setFeedbackOpen}
                        />
                      </Dialog>

                      {/* Tutorial & Quick Start dropdown */}
                      <Menu
                        id="tutorial-menu"
                        open={
                          tutorialMenuState &&
                          tutorialMenuState.id === "tutorial-menu"
                            ? true
                            : false
                        }
                        anchorEl={tutorialMenuState && tutorialMenuState.anchor}
                        getContentAnchorEl={undefined}
                        onClick={handleCloseTutorialMenu}
                        onClose={handleCloseTutorialMenu}
                        keepMounted={true}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "center",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "center",
                        }}
                      >
                        {path.startsWith("/brain-visualizer") && (
                          <MenuItem
                            onClick={() => {
                              setBrainVisualizerIntroOpen(true);
                            }}
                          >
                            <Style sx={{ color: "accents.main", mr: "7px" }} />{" "}
                            Quick Start
                          </MenuItem>
                        )}
                        <MenuItem
                          onClick={() => {
                            setTutorialOpen(true);
                          }}
                        >
                          <SchoolIcon
                            sx={{ color: "accents.main", mr: "7px" }}
                          />{" "}
                          Tutorials
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            setDemosOpen(true);
                          }}
                        >
                          <Slideshow
                            sx={{ color: "accents.main", mr: "7px" }}
                          />{" "}
                          Demos
                        </MenuItem>
                      </Menu>

                      {/* Button to open tutorial/quickstart dropdown */}
                      <IconButton
                        style={{ borderRadius: "5px", padding: "5px" }}
                        aria-label="Tutorials"
                        aria-controls="tutorial-menu"
                        aria-haspopup="true"
                        onClick={(event) => {
                          handleOpenTutorialMenu(event, "tutorial-menu");
                        }}
                      >
                        <Info />
                      </IconButton>

                      {/* Quick Start modal */}
                      {brainVisualizerIntroOpen && (
                        <BrainVisualizerIntro
                          dialogOpen={brainVisualizerIntroOpen}
                          setDialogOpen={setBrainVisualizerIntroOpen}
                          userOpened={true}
                        />
                      )}

                      {/* Tutorial modal */}
                      {tutorialOpen && (
                        <BookModal
                          open={tutorialOpen}
                          onClose={handleTutorialClose}
                          setParentError={setError}
                          setModalOpen={setTutorialOpen}
                          fetchData={getTutorialData}
                          header="Tutorials"
                        />
                      )}

                      {/* Demos modal */}
                      {demosOpen && (
                        <BookModal
                          open={demosOpen}
                          onClose={handleDemosClose}
                          setParentError={setError}
                          setModalOpen={setDemosOpen}
                          fetchData={getDemosData}
                          header="Demos"
                        />
                      )}

                      {/* Avatar */}
                      <IconButton
                        color="inherit"
                        aria-label="Account"
                        aria-controls="account-menu"
                        aria-haspopup="true"
                        style={{ borderRadius: "5px", padding: "5px" }}
                        onClick={(event) => {
                          handleOpenMenu(event, "account-menu");
                        }}
                      >
                        <Badge
                          color="primary"
                          variant="standard"
                          badgeContent={
                            auth.user?.roles?.includes("pro-user")
                              ? "PRO"
                              : "ULT"
                          }
                          invisible={
                            !auth.user?.roles?.includes("pro-user") &&
                            !auth.user?.roles?.includes("ultimate-user")
                          }
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          sx={{
                            ".MuiBadge-badge": {
                              fontSize: "0.5rem",
                              fontWeight: "600",
                              height: "10px",
                              minWidth: "3px",
                              bottom: "1.5px",
                              right: "3px",
                              backgroundColor: auth.user?.roles?.includes(
                                "pro-user"
                              )
                                ? "primary.main"
                                : "gold.main",
                            },
                          }}
                        >
                          <AccountCircleIcon title="account" />
                        </Badge>
                      </IconButton>
                    </Box>
                    <Menu
                      id="account-menu"
                      open={
                        menuState && menuState.id === "account-menu"
                          ? true
                          : false
                      }
                      anchorEl={menuState && menuState.anchor}
                      getContentAnchorEl={undefined}
                      onClick={handleCloseMenu}
                      onClose={handleCloseMenu}
                      keepMounted={true}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                    >
                      {/* {!path.startsWith("/brain-visualizer") && ( */}
                      <MenuItem onClick={() => linkClick("/lab")}>
                        <Science sx={{ color: "accents.main", mr: "7px" }} />
                        Laboratory
                      </MenuItem>
                      {/* )} */}
                      {/* {path.startsWith("/brain-visualizer") && (
                      <MenuItem
                        onClick={() => (window.location.href = "/lab")}
                      >
                        Laboratory
                      </MenuItem>
                    )} */}
                      <MenuItem onClick={() => linkClick("/settings/profile")}>
                        <Person sx={{ color: "accents.main", mr: "7px" }} />
                        Profile
                      </MenuItem>

                      {/* {!auth.user?.roles?.includes("ultimate-user") && (
                        <MenuItem
                          component={Link}
                          to="/pricing"
                          sx={{ color: "primary.main" }}
                        >
                          <ElectricBolt
                            sx={{ color: "primary.main", mr: "3px" }}
                          />
                          {auth.user?.roles?.includes("pro-user")
                            ? "Try Ultimate"
                            : "Try Pro"}
                        </MenuItem>
                      )} */}

                      <MenuItem onClick={() => linkClick("/hub")}>
                        <Hub sx={{ color: "accents.main", mr: "7px" }} />
                        Hub
                      </MenuItem>
                      <Divider />
                      {/* <MenuItem
                      onClick={async (event) => {
                        auth.signout();
                      }}
                    > */}
                      <MenuItem onClick={handleSignOut}>
                        <Logout sx={{ color: "accents.main", mr: "7px" }} />
                        Sign Out
                      </MenuItem>
                    </Menu>
                  </>
                )}

                {/* {!path.startsWith("/brain-visualizer") && (
                <IconButton
                  color="inherit"
                  onClick={darkMode.toggle}
                  style={{ opacity: 0.6 }}
                >
                  {darkMode.value && <NightsStayIcon />}

                  {!darkMode.value && <WbSunnyIcon />}
                </IconButton>
              )} */}
              </Hidden>
            </Toolbar>
          </Container>
        </AppBar>
        <Drawer
          anchor="right"
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
        >
          <List sx={{ width: 250 }} onClick={() => setDrawerOpen(false)}>
            {!auth.user && (
              <ListItemButton component={CustomLink} href="/">
                <ListItemText>Sign in</ListItemText>
              </ListItemButton>
            )}

            {auth.user && (
              <>
                <ListItemButton component={Link} to="/lab">
                  <ListItemText>Laboratory</ListItemText>
                </ListItemButton>
                <ListItemButton component={Link} to="/settings/account">
                  <ListItemText>Settings</ListItemText>
                </ListItemButton>
                <Divider />
                <ListItemButton onClick={handleSignOut}>
                  <ListItemText>Sign out</ListItemText>
                </ListItemButton>
              </>
            )}

            {/* <ListItem>
            <IconButton
              color="inherit"
              onClick={darkMode.toggle}
              style={{ opacity: 0.6 }}
            >
              {darkMode.value && <NightsStayIcon />}

              {!darkMode.value && <WbSunnyIcon />}
            </IconButton>
          </ListItem> */}
          </List>
        </Drawer>
      </Section>

      {leavePlayPath && (
        <CustomDialog
          header="Leave the current page and end your play session?"
          // text="(No data will be lost.)"
          isOpen={leavePlayPath}
          handleClose={() => setLeavePlayPath("")}
          confirmAction={() =>
            handleLeavePlay(accessToken, leavePlayPath, setLeavePlayPath)
          }
          cancelAction={() => setLeavePlayPath("")}
        />
      )}

      {/* Error message */}
      {error && (
        <CustomSnackbar
          message={error}
          setMessage={setError}
          severity="error"
        />
      )}

      {/* Info message */}
      {message && (
        <CustomSnackbar
          message={message}
          setMessage={setMessage}
          autoHide={12000}
          severity="info"
        />
      )}

      {/* Fullscreen loading spinner */}
      {fullscreenLoader && <FullscreenLoader message="Logging out..." />}
    </>
  );
});

export default Navbar;
