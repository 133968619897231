import { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { Cancel as CancelIcon, Loop as LoopIcon } from "@mui/icons-material";
import defaultImage from "../../img/robot-neutral.png";
import CarouselComplex from "../CarouselComplex";
import { getEmbodimentById } from "../../api/embodimentManagement";

const NavbarBehaviors = ({
  embodimentId,
  accessToken,
  setSnackbar,
  awaitAugment,
  handleChange,
}) => {
  const [behaviorsOpen, setBehaviorsOpen] = useState(false);
  const [behaviorsGenomes, setBehaviorsGenomes] = useState([]);

  async function handleBehaviorsOpen() {
    try {
      // call API with embodiment ID
      if (!accessToken) throw new Error("Unable to retrieve access token.");
      const res = await getEmbodimentById(accessToken, embodimentId);
      const personalities = res.data?.personalities;
      if (!personalities || personalities.length === 0) {
        alert("No personalities found for this embodiment.");
        return;
      }

      const items = personalities.map((personality) => {
        return {
          personality: personality,
          content: (
            <Box
              title={personality.title}
              sx={{
                display: "flex",
                flexDirection: "column-reverse",
                alignItems: "center",
                justifyContent: "space-between",
                height: "100%",
                padding: "5px",
              }}
            >
              <Box
                sx={{
                  height: "100%",
                  width: "100%",
                  flex: "2",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-end",
                }}
              >
                <img
                  src={personality.image_url || defaultImage}
                  alt="robot"
                  crossorigin="anonymous"
                  style={{
                    width: "100px",
                    maxHeight: "100px",
                    objectFit: "contain",
                  }}
                />
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                width="90%"
                sx={{ flex: "1.3" }}
              >
                <Typography
                  style={{
                    fontWeight: "bold",
                    maxWidth: "100%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {personality.title}
                </Typography>
                <Typography
                  style={{
                    fontSize: ".8rem",
                    maxWidth: "100%",
                    overflow: "hidden",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 2,
                    textOverflow: "ellipsis",
                    whiteSpace: "normal",
                  }}
                >
                  {personality.description}
                </Typography>
              </Box>
            </Box>
          ),
        };
      });
      //   handleClose();
      setBehaviorsGenomes(items);
      setBehaviorsOpen(true);
    } catch (err) {
      console.error(err);
    }
  }

  const handleBehaviorsClose = () => {
    setBehaviorsOpen(false);
  };

  return (
    <>
      {handleChange ? (
        <IconButton
          variant="outlined"
          size="small"
          color="primary"
          onClick={handleBehaviorsOpen}
          disabled={awaitAugment || !accessToken}
          sx={{ border: "1px solid", padding: "2px" }}
        >
          <LoopIcon sx={{ fontSize: "1.2rem" }} />
        </IconButton>
      ) : (
        <Button
          variant="outlined"
          size="medium"
          color="primary"
          onClick={handleBehaviorsOpen}
          startIcon={<LoopIcon />}
          disabled={awaitAugment || !accessToken}
        >
          Personality
        </Button>
      )}
      {/* Behaviors carousel popup */}
      <Dialog
        open={behaviorsOpen}
        onClose={handleBehaviorsClose}
        aria-labelledby="behaviors-title"
        sx={{
          "& .MuiDialog-container": {
            alignItems: "flex-start",
            position: "relative",
            top: "40px",
          },
        }}
        maxWidth="md"
      >
        <DialogTitle
          id="behaviors-title"
          sx={{ display: "flex", justifyContent: "flex-end", padding: "3px" }}
        >
          <IconButton size="small" onClick={handleBehaviorsClose}>
            <CancelIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ minWidth: "40vw", px: { xs: 1, md: 3 }, pt: 1 }}>
          <CarouselComplex
            items={behaviorsGenomes}
            handleBehaviorsClose={handleBehaviorsClose}
            accessToken={accessToken}
            handleChange={handleChange}
            setSnackbar={setSnackbar}
            // experiment={experiment}
          />
          {/* <CarouselFromLibrary behaviorsGenomes={behaviorsGenomes} /> */}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default NavbarBehaviors;

// const behaviors = [
//   {
//     title: "Chase",
//     image_link:
//       "https://storage.googleapis.com/embodiment_images/08959c0b-9c38-43ac-b3ac-8c7a37a2a5ba_1.b508de36c30ad6b1a3c6d421faa7ae34.jpeg.webp",
//     description: "Enables robot to detect movement and chase after them.",
//     embodiment_id: "em_412413423423",
//     behavior_id: "b_4234234234234",
//     genome_id: "g_12341231232312",
//   },
//   etc....
// ];
