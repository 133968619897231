import { apiRequestExternal } from "./apiRequestExternal";
import { apiFilterNonNull } from "./apiFilterNonNull";
import { apiRemoveSpaces } from "./apiRemoveSpaces";
import { apiValidateArguments } from "./apiValidateArguments";
import { logger } from "../util/logger";

// Get all embodiments, paginated
// optional queries: skip, limit
// optional params: embodimentId, environmentId, capabilities, species, createdBy, keyword, creationDateRange, modificationDateRange, rating
export async function getEmbodiments({
  accessToken,
  skip = 0,
  limit = 10,
  sortBy,
  search,
  filters,
  playable,
} = {}) {
  try {
    // Validate args
    if (!accessToken) throw new Error("Missing access token.");

    // Filter to only pass provided args in request body
    const body = apiFilterNonNull({
      search,
      filters,
    });

    let url = `${process.env.REACT_APP_COMPOSER_URL}/v1/public/regional/auth/user/embodiments/query?skip=${skip}&limit=${limit}`;

    if (sortBy) {
      sortBy = apiRemoveSpaces(sortBy);
      url += `&sort_by=${sortBy}`;
    }

    if (playable) {
      url += `&playable=${playable}`;
    }

    // Return custom response data
    const response = await apiRequestExternal(url, "POST", body, {
      Authorization: accessToken,
    });

    logger("Response in getEmbodiments function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in getEmbodiments function:", error);
    throw error;
  }
}

// Get one embodiment details by ID
export async function getEmbodimentById(accessToken, embodimentId) {
  try {
    // Validate args
    apiValidateArguments({ accessToken, embodimentId });

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.REACT_APP_COMPOSER_URL}/v1/public/regional/auth/user/embodiments/query?embodiment_id=${embodimentId}`,
      "GET",
      null,
      { Authorization: accessToken }
    );

    logger("Response in getEmbodimentById function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in getEmbodimentById function:", error);
    throw error;
  }
}

// Get list of embodiment filters
export async function getEmbodimentFilters(accessToken) {
  try {
    // Validate args
    if (!accessToken) throw new Error("Missing access token.");

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.REACT_APP_COMPOSER_URL}/v1/public/regional/auth/user/embodiments/filters`,
      "GET",
      null,
      { Authorization: accessToken }
    );

    logger("Response in getEmbodimentFilters function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in getEmbodimentFilters function:", error);
    throw error;
  }
}

// Get list of embodiment sort options
export async function getEmbodimentSortOptions(accessToken) {
  try {
    // Validate args
    if (!accessToken) throw new Error("Missing access token.");

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.REACT_APP_COMPOSER_URL}/v1/public/regional/auth/user/embodiments/sort_options`,
      "GET",
      null,
      { Authorization: accessToken }
    );

    logger("Response in getEmbodimentSortOptions function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in getEmbodimentSortOptions function:", error);
    throw error;
  }
}

// Get list of embodiment sort options
export async function getEmbodimentRegistrationLink(accessToken) {
  try {
    // Validate args
    if (!accessToken) throw new Error("Missing access token.");

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.REACT_APP_COMPOSER_URL}/v1/public/regional/auth/user/embodiments/generate_magic_url`,
      "GET",
      null,
      { Authorization: accessToken }
    );

    logger("Response in getEmbodimentRegistrationLink function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in getEmbodimentRegistrationLink function:", error);
    throw error;
  }
}

// Get embodiment connectivity instructions
export async function getEmbodimentConnectivityInstructions(
  accessToken,
  embodimentId
) {
  try {
    // Validate args
    apiValidateArguments({ accessToken, embodimentId });

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.REACT_APP_COMPOSER_URL}/v1/public/regional/auth/user/embodiments/connectivity_notes?embodiment_id=${embodimentId}`,
      "GET",
      null,
      { Authorization: accessToken }
    );

    logger(
      "Response in getEmbodimentConnectivityInstructions function:",
      response
    );
    return response;

    // Handle errors
  } catch (error) {
    console.error(
      "Error in getEmbodimentConnectivityInstructions function:",
      error
    );
    throw error;
  }
}

// Get embodiment integration instructions
export async function getEmbodimentIntegrationInstructions(
  accessToken,
  embodimentId
) {
  try {
    // Validate args
    apiValidateArguments({ accessToken, embodimentId });

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.REACT_APP_COMPOSER_URL}/v1/public/regional/auth/user/embodiments/genome_integration_notes?embodiment_id=${embodimentId}`,
      "GET",
      null,
      { Authorization: accessToken }
    );

    logger(
      "Response in getEmbodimentIntegrationInstructions function:",
      response
    );
    return response;

    // Handle errors
  } catch (error) {
    console.error(
      "Error in getEmbodimentIntegrationInstructions function:",
      error
    );
    throw error;
  }
}

// Send fact that user clicked on an external product link for an embodiment
export async function externalProductClick(accessToken, embodimentId) {
  try {
    // Validate args
    apiValidateArguments({ accessToken, embodimentId });

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.REACT_APP_COMPOSER_URL}/v1/public/regional/auth/user/embodiments/external_product_click`,
      "POST",
      { embodiment_id: embodimentId },
      { Authorization: accessToken }
    );

    logger("Response in externalProductClick function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in externalProductClick function:", error);
    throw error;
  }
}

// Trigger an embodiment personality's behavior
export async function triggerBehavior(
  accessToken,
  sessionId,
  embodimentId,
  personalityId,
  behaviorId
) {
  try {
    // Validate args
    apiValidateArguments({
      accessToken,
      sessionId,
      embodimentId,
      personalityId,
      behaviorId,
    });

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.REACT_APP_COMPOSER_URL}/v1/public/regional/auth/user/embodiments/trigger_behavior`,
      "POST",
      {
        session_id: sessionId,
        embodiment_id: embodimentId,
        personality_id: personalityId,
        behavior_id: behaviorId,
      },
      { Authorization: accessToken }
    );

    logger("Response in triggerBehavior function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in triggerBehavior function:", error);
    throw error;
  }
}

// Get default play object
export async function getEmbodimentDefaultPlayObject(accessToken) {
  try {
    // Validate args
    apiValidateArguments({ accessToken });

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.REACT_APP_COMPOSER_URL}/v1/public/regional/auth/user/embodiments/default_play_object`,
      "GET",
      null,
      { Authorization: accessToken }
    );

    logger("Response in getEmbodimentDefaultPlayObject function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in getEmbodimentDefaultPlayObject function:", error);
    throw error;
  }
}
