import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { logger } from "../../util/logger";
import getStatusCodeString from "../../util/websocketStatusCodes";

function Microbit({
  isMicrobitOpen,
  setIsMicrobitOpen,
  isMicrobitConnected,
  setIsMicrobitConnected,
}) {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const sessionId = params.get("id") || params.get("session_id");
  const clusterId = params.get("cluster") || params.get("cluster_id");
  const wsUrl = `wss://${sessionId}-feagi.${clusterId}.neurorobotics.studio/p9052?device=microbit`;

  useEffect(() => {
    logger("Microbit component mounted");

    return () => {
      logger("Microbit component unmounted");
    };
  }, []);

  // Connect/disconnect on switch click
  useEffect(() => {
    if (isMicrobitOpen && !isMicrobitConnected) {
      connectMicrobit();
    } else if (!isMicrobitOpen) {
      disconnectMicrobit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMicrobitOpen]);

  useEffect(() => {
    if (!isMicrobitConnected) {
      disconnectMicrobit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMicrobitConnected]);

  // UUIDs (Universally Unique Identifiers) for services used in Bluetooth Low Energy (BLE) communication, specifically tailored for the micro:bit
  const MBIT_UART_SERVICE = "6e400001-b5a3-f393-e0a9-e50e24dcca9e";
  const MBIT_UART_RX_CHARACTERISTIC = "6e400003-b5a3-f393-e0a9-e50e24dcca9e";
  const MBIT_UART_TX_CHARACTERISTIC = "6e400002-b5a3-f393-e0a9-e50e24dcca9e";
  const bluetoothSearchOptions = {
    acceptAllDevices: true,
    optionalServices: [MBIT_UART_SERVICE],
  };

  const wsRef = useRef(null);
  const microbitUARTRef = useRef(null);

  class MicroBitUART {
    constructor(rxCharacteristic, txCharacteristic) {
      this.rxCharacteristic = rxCharacteristic;
      this.txCharacteristic = txCharacteristic;
      this.decoder = new TextDecoder();
      this.txCharacteristic.startNotifications().then((characteristic) => {
        characteristic.addEventListener(
          "characteristicvaluechanged",
          this.onCharacteristicValueChanged.bind(this)
        );
      });
    }

    onCharacteristicValueChanged(event) {
      let valueAsString = this.decoder.decode(event.target.value);
      if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
        const formattedData = JSON.stringify({
          microbit: { data: valueAsString, timestamp: Date.now() },
        });
        wsRef.current.send(formattedData);
      }
    }

    async send(data) {
      const encoder = new TextEncoder();
      const encoded = encoder.encode(data);
      await this.rxCharacteristic.writeValue(encoded);
      logger("Sent to Microbit >>>", data);
    }
  }

  const connectWebSocket = () => {
    const newWs = new WebSocket(wsUrl);
    newWs.onopen = () => logger("WebSocket connection established");
    newWs.onmessage = (event) => {
      logger("Received message from server:", event.data);
      if (!microbitUARTRef.current) {
        console.error("Microbit UART not initialized. Ignoring message.");
      }
      microbitUARTRef.current?.send(event.data);
    };
    newWs.onerror = (error) => console.error("WebSocket error:", error);
    newWs.onclose = (event) => {
      logger(
        `WebSocket connection closed: Code ${event.code} ${getStatusCodeString(
          event.code
        )}.`
      );
    };
    wsRef.current = newWs;
  };

  const connectMicrobit = async () => {
    try {
      const device = await navigator?.bluetooth?.requestDevice(
        bluetoothSearchOptions
      );
      if (!device) {
        throw new Error(
          "Unable to get device info. Unsupported browser likely."
        );
      }
      const server = await device.gatt.connect();

      const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
      await delay(5000);

      const service = await server.getPrimaryService(MBIT_UART_SERVICE);

      const rxCharacteristic = await service.getCharacteristic(
        MBIT_UART_RX_CHARACTERISTIC
      );
      const txCharacteristic = await service.getCharacteristic(
        MBIT_UART_TX_CHARACTERISTIC
      );
      if (!rxCharacteristic || !txCharacteristic) {
        throw new Error("Unable to get characteristic info to make a UART.");
      }

      const uart = new MicroBitUART(rxCharacteristic, txCharacteristic);
      if (!uart) {
        throw new Error("Unable to create Microbit UART.");
      }
      microbitUARTRef.current = uart;

      setIsMicrobitConnected(true);
      console.log("Microbit connected");

      // Setup WebSocket connection after Microbit is connected
      connectWebSocket();

      device.ongattserverdisconnected = () => {
        console.log("Microbit disconnected");
        disconnectMicrobit();
      };
    } catch (error) {
      console.error("There was an error connecting to the Microbit:", error);
      disconnectMicrobit();
    }
  };

  const disconnectMicrobit = () => {
    console.log("Disconnection initiated");
    if (wsRef.current) {
      console.log("Disconnect: closing websocket");
      wsRef.current.close();
      wsRef.current = null;
    }

    if (navigator?.bluetooth?.getDevices) {
      navigator.bluetooth.getDevices().then((devices) => {
        devices.forEach((device) => {
          if (device.gatt.connected) {
            console.log("Disconnect: Disconnecting Bluetooth device");
            device.gatt.disconnect();
          }
        });
      });
    }

    if (microbitUARTRef.current) {
      console.log("Disconnect: Setting UART ref to null");
      microbitUARTRef.current = null;
    }

    console.log("Disconnect: Setting microbit/embodiment to closed");
    setIsMicrobitConnected(false);
    setIsMicrobitOpen(false);
  };

  // const toggleOpen = () => {
  //   if (isMicrobitConnected) {
  //     disconnectMicrobit();
  //   } else {
  //     connectMicrobit();
  //   }
  // };

  return (
    <>
      {/* <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex">
          <Typography>Cutebot</Typography> */}
      {/* <IconButton
            sx={{ fontSize: "1rem", padding: 0 }}
            onClick={handleEmbodimentOpen}
          >
            <InfoIcon style={{ fontSize: "1.5rem", padding: "4px" }} />
          </IconButton> */}
      {/* </Box>
        <Switch
          onClick={
            browserInfo?.browser === "Safari"
              ? () => setUnsupportedAlertOpen(true)
              : toggleOpen
          }
          checked={!!isMicrobitConnected}
        />
      </Box>
      <Snackbar
        open={unsupportedAlertOpen}
        autoHideDuration={6000}
        onClose={() => setUnsupportedAlertOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setUnsupportedAlertOpen(false)}
          severity="warning"
        >
          Safari does not currently support Bluetooth connectivity. Please
          switch to Chrome.
        </Alert>
      </Snackbar> */}
    </>
  );
}

export default Microbit;

// const helloClicked = () => {
//   //   ourMicrobitUART && ourMicrobitUART.send("f60#");
//   // };

//   // const backwardClicked = () => {
//   //   ourMicrobitUART && ourMicrobitUART.send("b60#");
//   // };

//   // const leftClicked = () => {
//   //   ourMicrobitUART && ourMicrobitUART.send("l60#");
//   // };

//   // const rightClicked = () => {
//   //   ourMicrobitUART && ourMicrobitUART.send("r60#");
//   // };

//   // const sayHelloBack = (message) => {
//   //   ourMicrobitUART && ourMicrobitUART.send("hello", "response");
//   // };

//   // const printMessage = () => {
//   //   setMessage("Hello, world!");
//   // };

/* <Box display="flex" justifyContent={"center"}>
        <button type="button" onClick={connectClicked}>
          Connect!
        </button>
        <button type="button" onClick={helloClicked}>
          Go forward
        </button>
        <button type="button" onClick={backwardClicked}>
          Go backward
        </button>
        <button type="button" onClick={leftClicked}>
          Go left
        </button>
        <button type="button" onClick={rightClicked}>
          Go right
        </button>
        <button onClick={printMessage}>Print Message</button>
        <p>{message}</p>
      </Box>  */

/* <div>
        {log.map((logEntry, index) => (
          <div key={index}>{logEntry}</div>
        ))}
      </div> */
