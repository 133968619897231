import { useEffect, useState } from "react";
import { ArrowBack, Info } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import PlayBehaviors from "./PlayBehaviors";
import PlayPersonality from "./PlayPersonality";
import PlayVirtualGames from "./PlayVirtualGames";
import PlayWebcam from "./PlayWebcam";

const PlayConnected = ({
  accessToken,
  setSnackbar,
  queryParameters,
  sessionId,
  clusterId,
  embodiment,
  embodimentId,
  embodimentType,
  config,
  instructions,
  setInstructionsOpen,
  setLeavePlayPath,
  physicalConnected,
  setPhysicalConnected,
  setIsBluetoothOpen,
}) => {
  const theme = useTheme();
  const isSmOrSmaller = useMediaQuery(theme.breakpoints.down("md"));
  const isAbove2XL = useMediaQuery(theme.breakpoints.up("2xl"));
  const isFromBV = queryParameters.get("ref") === "brain-visualizer";
  const isPhysical =
    embodimentType === "nrs-bt-device" || embodimentType === "user-ws-device";
  const [personality, setPersonality] = useState({});

  useEffect(() => {
    if (embodiment?.personalities?.[0]) {
      setPersonality(embodiment.personalities[0]);
    }
  }, [embodiment, isPhysical]);

  const handlePlaysClick = () => {
    if (embodimentId) {
      setLeavePlayPath("/play");
    } else {
      window.location.href = "/play";
    }
  };

  const handleConnectClick = () => {
    if (embodimentType === "nrs-bt-device") {
      if (physicalConnected) {
        setPhysicalConnected(false);
        // setIsMicrobitOpen(false);
      } else {
        setIsBluetoothOpen(true);
      }
    } else if (embodimentType === "user-ws-device") {
      setInstructionsOpen(true);
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        px: { xs: 3, md: 4, lg: 10, xl: 24 },
        py: 3,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 3,
      }}
    >
      {/* 📦 TOP CONTAINER (back to plays, title/how to play, connect/mute) 📦 */}
      <Box
        sx={{
          width: "100%",
          mb: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: { xs: "center", md: "flex-start" },
          gap: 2,
        }}
      >
        {/* back to Plays */}
        <Box sx={{ flex: 1 }}>
          {!isFromBV && (
            <Button
              variant="outlined"
              size="small"
              startIcon={<ArrowBack />}
              title="Return to Play Select"
              onClick={handlePlaysClick}
            >
              Plays
            </Button>
          )}
        </Box>

        {/* Play notes button OR title */}
        {isPhysical ? (
          <Box sx={{ flex: 1, display: "flex", justifyContent: "center" }}>
            <Button
              variant="outlined"
              size="small"
              onClick={() => setInstructionsOpen(true)}
              disabled={!instructions}
              title={
                instructions
                  ? "View play instructions"
                  : "Instructions unavailable"
              }
            >
              {isSmOrSmaller ? "How To?" : "How to Play"}
            </Button>
          </Box>
        ) : (
          <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
            <Typography
              sx={{
                flex: 2,
                fontSize: "1.2rem",
                fontWeight: "600",
                textAlign: "center",
              }}
            >
              {embodiment.embodiment_title}
            </Typography>
            <IconButton
              size="small"
              onClick={() => setInstructionsOpen(true)}
              disabled={!instructions}
            >
              <Info />
            </IconButton>
          </Box>
        )}

        {/* Connect button OR mute */}
        <Box
          sx={{
            flex: 1,
            // order: 3,
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: 1,
          }}
        >
          {isPhysical ? (
            <Button
              size="small"
              variant="outlined"
              onClick={handleConnectClick}
            >
              {physicalConnected ? "Disconnect" : "Connect"}
            </Button>
          ) : (
            <>
              {/* <Box sx={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
            <IconButton onClick={toggleMute} sx={{ color: "primary.main" }}>
              {isMuted ? <VolumeOffIcon /> : <VolumeUpIcon />}
            </IconButton>
          </Box>  */}
            </>
          )}
        </Box>
      </Box>

      {/* 📦 MIDDLE CONTAINER (robot image & title OR virtual P1-P2, gesture monitor) 📦 */}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: {
            xs: isPhysical ? "column" : "column-reverse",
            md: "row-reverse",
          },
          justifyContent: "center",
          gap: 4,
        }}
      >
        {/* Title & image OR virtual games */}
        {isPhysical ? (
          <Box
            sx={{
              width: "100%",
              mt: 2,
              mb: 3,
              padding: { xs: "15px", sm: 0 },
              display: "flex",
              justifyContent: "center",
              gap: 3,
              borderRadius: "5px",
              backgroundColor: { xs: "background.dark", sm: "transparent" },
            }}
          >
            {/* Image */}
            {embodiment.image_url && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  crossorigin="anonymous"
                  src={embodiment.image_url}
                  alt={embodiment.embodiment_title}
                  style={{
                    maxWidth: "150px",
                    maxHeight: "150px",
                    objectFit: "contain",
                  }}
                />
              </Box>
            )}
            {/* Title */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  fontSize: "1.5rem",
                  fontWeight: "600",
                }}
              >
                {embodiment.embodiment_title}
              </Typography>
              {embodiment.manufacturer && (
                <Typography>by {embodiment.manufacturer}</Typography>
              )}
            </Box>
          </Box>
        ) : (
          // Virtual games
          <PlayVirtualGames
            config={config}
            embodiment={embodiment}
            clusterId={clusterId}
            sessionId={sessionId}
            isSmOrSmaller={isSmOrSmaller}
            isAbove2XL={isAbove2XL}
          />
        )}

        {/* Webcam for Virtual */}
        {config?.webcam && !isPhysical && (
          <PlayWebcam
            config={config.webcam}
            setSnackbar={setSnackbar}
            isAbove2XL={isAbove2XL}
          />
        )}
      </Box>

      {/* 📦 BOTTOM ELEMENTS (physical only) 📦 */}
      {isPhysical && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            gap: { xs: 2, md: 4, xl: 8 },
          }}
        >
          {/* Webcam */}
          {config?.webcam && (
            <Box sx={{ mb: { xs: 3, md: 0 }, flex: 1 }}>
              <PlayWebcam config={config.webcam} setSnackbar={setSnackbar} />
            </Box>
          )}
          <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
            {/* Personality */}
            <PlayPersonality
              personality={personality}
              setPersonality={setPersonality}
              embodiment={embodiment}
              embodimentId={embodimentId}
              accessToken={accessToken}
              setSnackbar={setSnackbar}
            />
            {/* Behaviors */}
            {Array.isArray(personality?.behaviors) &&
              personality.behaviors.length > 0 && (
                <PlayBehaviors
                  accessToken={accessToken}
                  setSnackbar={setSnackbar}
                  sessionId={sessionId}
                  embodimentId={embodimentId}
                  personalityId={personality.personality_id}
                  behaviors={personality.behaviors}
                />
              )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default PlayConnected;
