import { Alert, Box, Button, Typography } from "@mui/material";
import MagicLink from "../MagicLink";
import { useEffect, useState } from "react";
import DisplayMarkdown from "../../util/DisplayMarkdown";
import { getAgents } from "../../api/feagiAgent";
import PageLoader from "../PageLoader";
import PlayBluetooth from "./PlayBluetooth";
import { ArrowBack } from "@mui/icons-material";

const PlayPhysical = ({
  accessToken,
  setSnackbar,
  sessionId,
  clusterId,
  embodiment,
  physicalConnected,
  setPhysicalConnected,
  setIsBluetoothOpen,
}) => {
  const [loading, setLoading] = useState(true);
  const CHECK_INTERVAL = 30000;
  const [link, setLink] = useState("");
  const [naw, setNaw] = useState("");

  console.log("play embodiment", embodiment);

  const customSetError = (message) => {
    setSnackbar({ message, severity: "error" });
  };

  // Call on page load & periodically to check if user-ws-device is connected
  useEffect(() => {
    if (embodiment.agent_type === "user-ws-device") {
      async function callGetAgents() {
        try {
          const res = await getAgents(sessionId, clusterId);
          if (res?.data?.includes(embodiment.controller_id)) {
            setPhysicalConnected(true);
          }
        } catch (err) {
          console.error(err);
        } finally {
          setLoading(false);
        }
      }

      if (sessionId && clusterId && embodiment?.controller_id) {
        callGetAgents();
        const intervalId = setInterval(callGetAgents, CHECK_INTERVAL);
        return () => clearInterval(intervalId);
      } else {
        console.error(
          `Missing required props for PlayPhysical. Session ID: ${sessionId}, Cluster ID: ${clusterId}, Controller ID: ${embodiment?.controller_id}`
        );
        setSnackbar({
          message:
            "Unable to fetch required internal information. Please reload, or restart play if issues persist.",
          severity: "error",
        });
        setLoading(false);
      }
    } else if (embodiment.agent_type === "nrs-bt-device") {
      setLoading(false);
    }
  }, [
    sessionId,
    clusterId,
    embodiment?.controller_id,
    embodiment.agent_type,
    setPhysicalConnected,
    loading,
    setSnackbar,
  ]);

  // Check connection when user clicks button
  const handleConnectClaim = async () => {
    try {
      setNaw("");
      const res = await getAgents(sessionId, clusterId);
      if (res?.data?.includes(embodiment.controller_id)) {
        setPhysicalConnected(true);
      } else {
        setNaw(
          "It seems your robot is not connected. Please review the instructions and retry."
        );
      }
    } catch (err) {
      console.error(err);
      setSnackbar({
        message:
          "Sorry, there was an error confirming the connection. Please reload if issues persists.",
        severity: "error",
      });
    }
  };

  if (loading) return <PageLoader />;

  return (
    <Box
      sx={{
        width: "100%",
        my: 8,
        px: { xs: 2, sm: 4, md: 8, xl: 40 },
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 3,
      }}
    >
      {/* {error && <Alert severity="error">{error}</Alert>} */}
      {/* back to Plays & image */}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          gap: { xs: 2, md: 0 },
        }}
      >
        <Box sx={{ flex: 1 }}>
          <Button
            variant="outlined"
            size="small"
            startIcon={<ArrowBack />}
            title="Return to Play Select"
            href="/play"
          >
            Plays
          </Button>
        </Box>
        <Box
          sx={{
            flex: 2,
            width: "100%",
            maxWidth: "200px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {embodiment?.image_url && (
            <img
              src={embodiment.image_url}
              alt={embodiment.embodiment_title}
              crossorigin="anonymous"
              style={{
                width: "100%",
                objectFit: "contain",
              }}
            />
          )}
        </Box>
        <Box sx={{ flex: 1 }} />
      </Box>

      <Typography
        variant="h4"
        sx={{ fontSize: { xs: "2rem", md: "2.125rem" } }}
      >
        Connect Your Robot
      </Typography>

      {embodiment.agent_type === "user-ws-device" && (
        <MagicLink
          accessToken={accessToken}
          embodimentId={embodiment.embodiment_id}
          setError={customSetError}
          link={link}
          setLink={setLink}
        />
      )}

      {embodiment.agent_type === "nrs-bt-device" && (
        <PlayBluetooth
          isEmbodimentConnected={physicalConnected}
          setIsEmbodimentConnected={setPhysicalConnected}
          setIsBluetoothOpen={setIsBluetoothOpen}
        />
      )}

      <Box
        sx={{
          p: "8px 24px",
          borderRadius: "10px",
          border: "1px solid #343434",
          backgroundColor: "#191c1f",
        }}
      >
        <DisplayMarkdown markdownFromDb={embodiment.connectivity_notes} />
      </Box>

      {embodiment.agent_type === "user-ws-device" && (
        <>
          <Typography variant="h4">All Done?</Typography>

          {naw && <Alert severity="warning">{naw}</Alert>}

          <Button variant="contained" onClick={handleConnectClaim}>
            Test Connection
          </Button>
        </>
      )}
    </Box>
  );
};

export default PlayPhysical;
