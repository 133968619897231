import { useState } from "react";
import { useAuth } from "../../util/auth";
import { Alert, Box, Container } from "@mui/material";
import Section from "../Section";
import ReauthModal from "../ReauthModal";
import SettingsNav from "./SettingsNav";
import SettingsAccount from "./SettingsAccount";
import SettingsBilling from "./SettingsBilling";
import SettingsGenomes from "./SettingsGenomes";
import SettingsProfile from "./SettingsProfile";
import SettingsRobots from "./SettingsRobots";

function SettingsSection(props) {
  const auth = useAuth();
  const [formAlert, setFormAlert] = useState(null);

  // State to control whether we show a re-authentication flow
  // Required by some security sensitive actions, such as changing password.
  const [reauthState, setReauthState] = useState({
    show: false,
  });

  const validSections = {
    account: true,
    profile: true,
    billing: true,
    robots: true,
    genomes: true,
  };

  const section = validSections[props.section] ? props.section : "account";

  // Handle status of type "success", "error", or "requires-recent-login"
  // We don't treat "requires-recent-login" as an error as we handle it
  // gracefully by taking the user through a re-authentication flow.
  const handleStatus = (status) => {
    if (!status) {
      // Clear any existing message
      setFormAlert(null);
      return;
    }

    const { type, message, callback } = status;

    if (type === "requires-recent-login") {
      // First clear any existing message
      setFormAlert(null);
      // Then update state to show re-authentication modal
      setReauthState({
        show: true,
        // Failed action to try again after reauth
        callback: callback,
      });
    } else {
      // Display message to user (type is success or error)
      setFormAlert({
        type: type,
        message: message,
      });
    }
  };

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      {reauthState.show && (
        <ReauthModal
          callback={reauthState.callback}
          provider={auth.user.providers[0]}
          onDone={() => setReauthState({ show: false })}
        />
      )}

      <SettingsNav activeKey={section} />
      <Box mt={5}>
        <Container
          maxWidth="md"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {formAlert && (
            <Box mb={2}>
              <Alert
                severity={formAlert.type}
                onClose={() => setFormAlert(null)}
              >
                {formAlert.message}
              </Alert>
            </Box>
          )}

          {section === "account" && <SettingsAccount onStatus={handleStatus} />}

          {section === "profile" && <SettingsProfile onStatus={handleStatus} />}

          {section === "genomes" && <SettingsGenomes onStatus={handleStatus} />}

          {section === "billing" && <SettingsBilling onStatus={handleStatus} />}

          {section === "robots" && <SettingsRobots onStatus={handleStatus} />}
        </Container>
      </Box>
    </Section>
  );
}

export default SettingsSection;
