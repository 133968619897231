import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";

const PlayVirtualGames = ({
  config,
  embodiment,
  clusterId,
  sessionId,
  isSmOrSmaller,
  isAbove2XL,
}) => {
  const [embodimentId, setEmbodimentId] = useState(
    embodiment?.embodiment_id || ""
  );
  const [dimensions, setDimensions] = useState(embodiment?.dimensions || {});

  useEffect(() => {
    if (embodiment) {
      setEmbodimentId(embodiment?.embodiment_id || "");
      setDimensions(embodiment?.dimensions || { aspect_ratio: 1 });
    }
  }, [embodiment]);

  const htmlAppMaker = (
    player,
    title,
    feagiIndex,
    outputFeagiIndex,
    outputMotorFeagiIndex,
    port
  ) => {
    return (
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 1,
        }}
      >
        <Typography
          variant="h3"
          sx={{
            mb: 1,
            fontSize: "1.3rem",
            fontWeight: 600,
            textAlign: "center",
          }}
        >
          {title}
        </Typography>
        <iframe
          muted="true"
          title={embodimentId}
          sandbox="allow-scripts allow-popups allow-same-origin allow-forms allow-top-navigation-by-user-activation allow-top-navigation allow-modals allow-popups-to-escape-sandbox"
          allow="cross-origin-isolation"
          src={`${process.env.PUBLIC_URL}/static/${embodimentId}/index.html?feagi_url=${sessionId}-feagi.${clusterId}.neurorobotics.studio&port_disabled=true&ws_url=wss://${sessionId}-feagi.${clusterId}.neurorobotics.studio/p${port}&http_type=HTTPS://&capabilities=input%2Ecamera%2E0%2Efeagi_index%3D${feagiIndex}%7Coutput%2Emotion_control%2E0%2Efeagi_index%3D${outputFeagiIndex}%7Coutput%2Emotor%2E0%2Efeagi_index%3D${outputMotorFeagiIndex}&player=${player}`}
          style={{
            width: "100%",
            maxWidth: isSmOrSmaller
              ? "300px"
              : `min(${isAbove2XL ? "900px" : "600px"}, calc(300px * ${
                  dimensions?.aspect_ratio
                }))`, // in calc, maxWidth becomes what it should be with a maxHeight of 300px and aspect ratio maintained (applying maxHeight directly to iframe breaks aspect ratio)
            minWidth: dimensions.min_width || undefined,
            minHeight: dimensions.min_height || undefined,
            aspectRatio: dimensions?.aspect_ratio,
            backgroundColor: "white",
            pointerEvents: player === 2 ? "none" : "auto",
          }}
        />
      </Box>
    );
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: {
          xs: "column",
          lg:
            !dimensions?.aspect_ratio || dimensions.aspect_ratio < 1.2
              ? "row"
              : "column",
        },
        gap: {
          xs: 4,
          lg:
            !dimensions?.aspect_ratio || dimensions.aspect_ratio < 1.2 ? 4 : 2,
        },
      }}
    >
      {config?.player_1 != null &&
        htmlAppMaker(
          1,
          config.player_1?.label,
          config.player_1?.capabilities?.input?.camera?.[0]?.feagi_index,
          config.player_1?.capabilities?.output?.motion_control?.[0]
            ?.feagi_index,
          config.player_1?.capabilities?.output?.motor?.[0]?.feagi_index,
          "9055"
        )}
      {config?.player_2 != null &&
        htmlAppMaker(
          2,
          config.player_2?.label,
          config.player_2?.capabilities?.input?.camera?.[0]?.feagi_index,
          config.player_2?.capabilities?.output?.motion_control?.[0]
            ?.feagi_index,
          config.player_2?.capabilities?.output?.motor?.[0]?.feagi_index,
          "9056"
        )}
    </Box>
  );
};

export default PlayVirtualGames;
