import { useEffect, useState } from "react";
import { Alert, Button } from "@mui/material";
import getBrowserInfo from "../../util/getBrowserInfo";
// import Microbit from "./embodiments-code/Microbit";

const PlayBluetooth = ({
  isEmbodimentConnected,
  setIsEmbodimentConnected,
  setIsBluetoothOpen,
}) => {
  const [browser, setBrowser] = useState("");
  // const [isBluetoothOpen, setIsBluetoothOpen] = useState(false);

  // Fetch browser info to check Bluetooth support
  useEffect(() => {
    async function fetchBrowserInfo() {
      const { browser } = await getBrowserInfo();
      setBrowser(browser);
    }
    fetchBrowserInfo();
  }, []);

  // Open/close Microbit on button click
  function handleButtonClick() {
    if (!isEmbodimentConnected) {
      setIsBluetoothOpen(true);
    } else {
      setIsEmbodimentConnected(false);
    }
  }

  return (
    <>
      {(browser === "Safari" || browser === "Firefox") && (
        <Alert severity="warning" sx={{ width: "100%", mb: 2 }}>
          As of our last knowledge, your browser does not support Bluetooth
          connections. If connection does not work, please switch to Chrome.
        </Alert>
      )}

      <Button variant="contained" onClick={handleButtonClick}>
        {isEmbodimentConnected ? "Loading..." : "Connect"}
      </Button>

      {/* {isBluetoothOpen && (
        <Microbit
          isMicrobitOpen={isBluetoothOpen}
          setIsMicrobitOpen={setIsBluetoothOpen}
          isMicrobitConnected={isEmbodimentConnected}
          setIsMicrobitConnected={setIsEmbodimentConnected}
        />
      )} */}
    </>
  );
};

export default PlayBluetooth;
