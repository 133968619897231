import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
// import { getAuth, signInWithCustomToken } from "firebase/auth";
// import { firebaseApp } from "../../util/firebase";
import PageLoader from "../PageLoader";
import { Box, Button, Typography } from "@mui/material";
import { getOAuthFirebaseToken } from "../../api/loginManagement";
import { useAuth } from "../../util/auth";
import getBrowserInfoForDb from "../../util/getBrowserInfoForDb";
import { logger } from "../../util/logger";

function OAuthCallbackHandler() {
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const provider = new URLSearchParams(location.search).get("provider");
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(true);
  const [showLogin, setShowLogin] = useState(false);

  useEffect(() => {
    if (!provider) {
      setErrorMessage("Invalid OAuth URL (no provider). Please retry:");
      setShowLogin(true);
      setLoading(false);
    }

    async function validateAndSignIn() {
      try {
        // Get browser info for server
        let browserInfo;
        try {
          browserInfo = await getBrowserInfoForDb();
        } catch (err) {
          console.error("Error getting browser info:", err);
        }
        // Ask server for Firebase token
        const res = await getOAuthFirebaseToken(provider, browserInfo);
        const firebaseToken = res.data.access_token;
        if (typeof firebaseToken !== "string") {
          throw new Error("Missing or wrong data type for token.");
        }
        // Call google analytics conversion tracking
        try {
          const isSignup = res.data.is_signup;
          if (
            isSignup &&
            process.env.REACT_APP_NODE_ENV === "production" &&
            window &&
            typeof window.gtag_report_conversion === "function"
          ) {
            window.gtag_report_conversion();
          }
        } catch (err) {
          logger("Error calling gtag_report_conversion:", err);
        }
        logger("Attempting Firebase login");
        // const auth = getAuth(firebaseApp);
        // logger("Initialized Firebase:", auth);
        // This will trigger the onAuthStateChanged listener in auth.js
        await auth.callSignInWithCustomToken(firebaseToken);
        logger("Firebase login completed, redirecting to lab");
        // Redirect to '/lab' after successful sign-in
        navigate("/lab");
      } catch (err) {
        console.error(err);
        if (err.status === 409) {
          setErrorMessage(
            "An account has already been created with this email address. Please sign in with email and password."
          );
          setShowLogin(true);
        } else if (err.status === 451) {
          navigate("/region-unsupported");
        } else {
          setErrorMessage(
            "Sorry, an error occurred completing your OAuth validation. Please retry."
          );
          setShowLogin(true);
        }
        setLoading(false);
      }
    }

    provider && validateAndSignIn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, provider]);

  if (loading) {
    return <PageLoader />;
  }

  if (errorMessage) {
    return (
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 2,
        }}
      >
        <Typography fontWeight="500">{errorMessage}</Typography>
        {showLogin && (
          <Button variant="contained" component={Link} to="/" color="primary">
            Sign in
          </Button>
        )}
      </Box>
    );
  }
}

export default OAuthCallbackHandler;
